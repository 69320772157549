import React from 'react';
import Typical from 'react-typical'
import './App.css';

const steps = [
  'hello 👋', 1000,
  'how are you?', 1000,
  'nice to meet you', 1000,
  'you can email me mario@0day.works', 2000,
  'PGP pub @ one.0day.works ', 1000,
];

function App() {
  return (
    <div className="App">
	  <Typical wrapper="span" steps={steps} loop={1} className={'caca'} />
    </div>
  );
}

export default App;
